import React from 'react';

const Check = () => {
    return (
        <svg viewBox="0 0 24 24" id="check" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#aa)"><path d="M13.125 0C6.498 0 0 5.373 0 12s6.498 12 13.125 12S24 17.502 24 10.875 19.752 0 13.125 0Zm0 22.5C7.533 22.5 2.25 16.842 2.25 11.25c0-6.12 3.408-9.375 9-9.375 5.592 0 11.344 3.656 11.344 10.875 0 5.592-3.877 9.75-9.469 9.75Z"/><path d="m4.773 10.615 2.39-1.472 4.688 6.188 7.18-7.476.742.632-7.07 9.654-7.93-7.526Z"/></g><defs><clipPath id="aa"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>
    );
};

const Copy = () => {
    return (
        <svg fill="none" viewBox="0 0 24 24" id="copy" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#ba)"><path d="M24 0 4.924 1.192l.737 7.37H1.125L0 23.25l16.563.75-.453-6.209 6.698.49L24 0Zm-8.203 23.297L.984 21.75 2.813 9.281l2.946.26.755 7.548 8.172.598 1.11 5.61ZM8.45 16.294l-2.5-13.28 17.006-1.871-2.136 14.903-12.37.248Z" fill="#020202"/></g><defs><clipPath id="ba"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>
    )
}

const Cup = () => {
    return (
        <svg fill="none" viewBox="0 0 24 24" id="cup" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M24 7.938c0 3-2.654 6.125-4.5 6.125-.254 2.717-2.619 4.578-5.312 5.244v3.475l4.95.25.44.968H7.266l.691-1.532 4.903.247-.665-3.143c-2.875.068-5.635-1.258-6.232-4.377l-.268-1.4-.117-.61C3.49 13.185 0 11.375 0 8.313c0-3.375 1.693-4.188 3.781-4.188.012 0 .023 0 .034.002l.03.002-.583-3.041L20.813 0l-.345 3.696c1.847 0 3.533 1.43 3.533 4.242Zm-1.062-.282c0-2.093-1.366-3.093-2.5-3.093a.22.22 0 0 0-.031.002.299.299 0 0 1-.02.002C20.224 6.301 20 8.701 19.693 12c1.134 0 3.245-1.688 3.245-4.344ZM4.014 5.014c-.929.09-2.514.717-2.514 2.582 0 2.874 2.816 4.478 3.906 4.69L4.014 5.014Zm11.674 2.59-1.524 1.754 1.132 3.023-2.33-1.645-2.41 1.645.905-2.71-1.648-1.167 2.147-.33.83-2.485.835 2.23 2.063-.316Z" fill="#000"/></svg>
    )
}

const Shit = () => {
    return (
        <svg fill="none" viewBox="0 0 16 16" id="shit2"
             xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#oa)" fill="#000">
                <path d="m10.572 6.242-.03-.001h.03ZM10.542 6.24c-.006 0-.005 0 0 0Z"/>
                <path d="M13.755 10.526c.407-.455.662-1.05.662-1.709 0-2.089-1.802-2.575-1.802-2.575s1.743-3.605-4.377-3.605c-2.352 0 0-2.575 0-2.575s-3.68.507-5.15 2.575c-1.195 1.684 0 3.605 0 3.605s-2.06.534-2.06 2.575c0 .659.256 1.254.663 1.709a2.57 2.57 0 0 0 .883 4.985l10.298.476c2.057.084 2.575-1.629 2.575-3.05a2.569 2.569 0 0 0-1.692-2.411Z"/>
            </g>
            <defs>
                <clipPath id="oa">
                    <path fill="#fff" d="M0 0h16v16H0z"/>
                </clipPath>
            </defs>
        </svg>
    )
}

const Telegram = () => {
    return (
        <svg fill="none" viewBox="0 0 24 24" id="telegram"
             xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.788 14.02a.747.747 0 0 0 .132.031c.283.667.564 1.334.844 2.002.503 1.202 1.01 2.44 1.121 2.796.139.438.285.736.445.94.083.104.178.196.29.266a1.189 1.189 0 0 0 .981.097c.107-.037.21-.087.304-.15L9.91 20l2.826-1.762 3.265 2.502c.048.037.1.068.156.093.392.17.772.23 1.13.182.356-.05.639-.199.85-.368.242-.195.436-.445.564-.728l.009-.022.003-.008.002-.004v-.002l.001-.001a.752.752 0 0 0 .04-.133l2.98-15.025a.753.753 0 0 0 .014-.146c0-.44-.166-.859-.555-1.112-.334-.217-.705-.227-.94-.209-.252.02-.486.082-.643.132-.088.028-.175.06-.26.094l-.011.005-16.714 6.556-.002.001a2.521 2.521 0 0 0-.547.281c-.227.155-.75.581-.661 1.285.07.56.454.905.689 1.071.128.091.25.156.34.199.04.02.126.054.163.07l.01.003 3.169 1.066Zm14.138-9.152h-.002a.819.819 0 0 1-.026.011L3.164 11.444a.81.81 0 0 1-.026.01l-.01.003a1.1 1.1 0 0 0-.09.04.86.86 0 0 0 .086.043l3.142 1.058c.056.019.11.044.16.076l10.377-6.075.01-.005a1.998 1.998 0 0 1 .441-.199c.09-.028.357-.107.645-.014a.854.854 0 0 1 .588.689.84.84 0 0 1 .003.424c-.07.275-.262.489-.437.653-.15.14-2.096 2.016-4.015 3.868l-2.613 2.52-.465.45 5.872 4.502c.08.033.165.047.251.04a.23.23 0 0 0 .117-.052.495.495 0 0 0 .103-.12l.002-.001 2.89-14.573c-.091.022-.18.05-.267.086l-.002.001Zm-8.461 12.394-1.172-.898-.284 1.805 1.456-.907Zm-2.247-2.68 1.165-1.125 2.613-2.522.973-.938-6.52 3.817.035.082c.411.971.818 1.945 1.22 2.92l.283-1.8a.744.744 0 0 1 .231-.434Z" fill="#000"/>
        </svg>
    )
}

const X = () => {
    return (
        <svg fill="none" viewBox="0 0 20 20" id="twitter"
             xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#ra)">
                <mask id="rb" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                    <path d="M0 0h20v20H0V0Z" fill="#fff"/>
                </mask>
                <g mask="url(#rb)">
                    <path d="M15.75.938h3.067l-6.7 7.677L20 19.063h-6.171L8.99 12.728l-5.528 6.335H.393l7.166-8.214L0 .939h6.329l4.365 5.79L15.75.937Zm-1.079 16.285h1.7L5.4 2.682H3.577l11.094 14.541Z" fill="#000"/>
                </g>
            </g>
            <defs>
                <clipPath id="ra">
                    <path fill="#fff" d="M0 0h20v20H0z"/>
                </clipPath>
            </defs>
        </svg>
    )
}

const Close = () => {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M17.7765 16.695C17.7765 16.695 16.906 18.663 16.2535 19.1975C15.4945 19.821 15.1945 19.187 15.1945 19.187C15.1945 19.187 13.074 14.998 9.9355 10.8125C9.8965 10.862 9.8595 10.911 9.82 10.961C6.704 14.8855 3.067 19.1115 3.067 19.1115C3.067 19.1115 2.655 19.6015 2.108 19.1115C1.397 18.474 0 17.173 0 17.173C0 17.173 4.4895 13.0845 8.146 9.15C8.2095 9.081 8.2715 9.0125 8.335 8.944C5.5095 4.9855 1.78 0.5725 1.7885 0.604L2.4285 0C2.4285 0 6.3815 3.601 10.018 7.4295C12.6095 4.552 14.6455 1.872 14.6455 1.872L15.2185 2.5C15.2185 2.5 13.7175 5.406 11.4205 8.9335C15.0715 12.935 17.7765 16.695 17.7765 16.695Z" fill="black"/>
        </svg> 
    )
}

const Icons = {
    Check,
    Copy,
    Cup,
    Shit,
    X,
    Telegram,
    Close
}

export default Icons