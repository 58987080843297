import React, { useState, useEffect } from 'react';
import Icons from "../../../Common/IconsComponent";
import { usePopup } from '../../../Popups/PopupContext.js';

const TaskList = () => {
    const [completedTasks, setCompletedTasks] = useState([]);
    const { showPopup, hidePopup, heroState, setHeroState } = usePopup();
    const [tasks] = useState([
        { name: 'follow tg channel', icon: <Icons.Telegram /> },
        { name: 'join the tg chat', icon: <Icons.Telegram /> },
        { name: 'follow twitter', icon: <Icons.X /> }
    ]);

    const handleTaskClick = (taskName) => {
        setCompletedTasks(prevState => {
            if (prevState.includes(taskName)) {
                return prevState; // Task is already completed, no need to change the state
            }
            return [...prevState, taskName];
        });
    };

    useEffect(() => {
        if (completedTasks.length === tasks.length) {
            //navigate('/home/claimer');
            setHeroState('claim_now')
            hidePopup();
        }
    }, [completedTasks, tasks.length, hidePopup]);

    return (
        <div className="main__tasks tasks">
            <ul className="tasks__list">
                {tasks.map((task, index) => (
                    <li key={index} className="tasks__item">
                        <a
                            href="#"
                            className={`tasks__link ${completedTasks.includes(task.name) ? 'tasks__link_done animate__animated animate__tada' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                handleTaskClick(task.name);
                            }}
                        >
                            <div className="tasks__icon">
                                {task.icon}
                            </div>
                            <div className="tasks__name">{task.name}</div>
                            <div className="tasks__value">
                                {completedTasks.includes(task.name) ? (
                                    <>
                                        <Icons.Check /> +1
                                    </>
                                ) : (
                                    <>
                                        +1 <Icons.Shit />
                                    </>
                                )}
                            </div>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TaskList;
