import React from "react";
import { useNavigate  } from 'react-router-dom';

const ConnectWalletWidget = () => {

    const navigate = useNavigate();

    const handleConnectWallet = () => {
        navigate('/home/tasks')
    };

    return (
        <div className="main__widget_section">
            <div className="main__text animate__animated animate__fadeInUp animate__delay-0.5s">connect your wallet <br/> to start $hitting</div>

            <button className="main__btn btn-black animate__animated animate__fadeInUpBig animate__delay-0.5s" data-modal="connect-shit-wallet" onClick={handleConnectWallet}>
                connect wallet
            </button>
        </div>
    );
};

export default ConnectWalletWidget;