import React, { useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import imgLogo from '../../../assets/img/logo.webp';
import imgPotato from '../../../assets/img/splash/potato_crown.png';
//import HeroComponent from '../Home/HeroComponent.jsx';

const SplashScreen = () => {
    const navigate = useNavigate();
      

    useEffect(() => {
        // new WOW.WOW({
        //     live: false
        //   }).init();
        /*
        const timer = setTimeout(() => {
            navigate('/home');
        }, 3000); // 3 seconds delay
        return () => clearTimeout(timer);*/
    }, [navigate]);

    const handleRedirect = () => {
        navigate('home')
    };

    return (
        <div className="splash" onClick={handleRedirect}>
            <div className="splash__main">
                <div className="splash__logo ">
                    {/* <HeroComponent /> */}
                    <img src={imgPotato} className='animate__animated animate__rotateIn animate__duration-0.5s'/>
                </div>
                <h1 className="splash__title animate__animated animate__backInRight animate__delay-0.5s animate__duration-1s">The $hit</h1>
            </div>
            <p className="splash__text animate__animated animate__delay-1.5s animate__backInUp animate__duration-2s">Vote and earn</p>
        </div>
    );
};

export default SplashScreen;