import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetTime }) => {
    const calculateTimeLeft = () => {
        const difference = targetTime - new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [targetTime]);

    return (
        <div className="main__text">
            {timeLeft.hours || '00'}:{timeLeft.minutes || '00'}:{timeLeft.seconds || '00'}
        </div>
    );
};

export default CountdownTimer;