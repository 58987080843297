import React from "react";
import { usePopup } from '../../Popups/PopupContext.js';
import Icons from "../../Common/IconsComponent.jsx";
import CountdownTimer from './Claimer/CountdownTimer.jsx';

const ClaimerWidget = () => {
    const { showPopup, hidePopup, heroState, setHeroState, setUserBalance } = usePopup();
    const handleClaim = () => {
        setHeroState('default')
        setUserBalance(3)
    }
    const handleDailyTasks = () => {
        alert('Feature in developent mode');
    }
    const targetTime = new Date(new Date().getTime() + 60 * 60 * 1000); // 1 hour later
    return (
        <div className="main__widget_section">
           
            {heroState == 'claim_now' ? (
                <>
                <div className="main__text">you made your<br/> first $hit</div>
                <button className="main__btn btn-black" data-modal="connect-shit-wallet" onClick={handleClaim}>
                    Claim
                    <span>
                        +3
                        <Icons.Shit/>
                    </span>
                </button>
                </>
            ) : (
                <>
                <div className="main__text">next claim in  <CountdownTimer targetTime={targetTime} /></div>
                <button className="main__btn btn-black" data-modal="connect-shit-wallet" onClick={handleDailyTasks}>
                    Daily Tasks
                   
                </button>
                </>
            )}
            
        </div>
    );
};

export default ClaimerWidget;