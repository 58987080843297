//import logo from './logo.svg';
//import './App.css';
import './assets/js/scripts.js';
import './assets/scss/style.scss';
import 'animate.css';
import { BrowserRouter, Route, Routes, createBrowserRouter, RouterProvider  } from 'react-router-dom';
import SplashScreen from './components/Screens/Splash/SplashScreen.jsx';
import HomeScreen from './components/Screens/Home/HomeScreen.jsx';
import { PopupProvider }  from './components/Popups/PopupContext.js';
import Popup  from './components/Popups/Popup.jsx';

const router = createBrowserRouter([
    {
        path: '/',
        element: <SplashScreen />,
    },
    {
        path: '/home',
        element: <HomeScreen />,
    },
    {
        path: '/home/*',
        element: <HomeScreen />,
    },
]);

function App() {
  return (
    <PopupProvider>
        <RouterProvider router={router}/>
        <Popup />
      </PopupProvider>
   
  );
}

export default App;
