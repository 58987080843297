import React, {useState, useEffect} from "react";
import { usePopup } from '../../Popups/PopupContext.js';
import Icons from "../../Common/IconsComponent";
import { CSSTransition } from 'react-transition-group';
import TaskList from './Tasks/TaskList.jsx';
import TaskForm from './Tasks/TaskForm.jsx';


const MainTasksWidget = () => {
    const { showPopup, hidePopup } = usePopup();
    const [showForm, setShowForm] = useState(false);

    const showTaskPopup = () => {

    }

    const handleSP = () => {
        //setShowForm(!showForm);
        setShowForm(prevShowForm => !prevShowForm);
    };

    useEffect(() => {
        console.log(showForm);
    }, [showForm]);


    const handleMakeClick = () => {
        showPopup(<TaskList/>);
    };

return (

    <div className="main__widget_section">

        <div className="main__text animate__animated animate__fadeInUp animate__delay-0.5s">complete tasks <br/> to make some $hit</div>

        <button className="main__btn btn-black animate__animated animate__fadeInUpBig animate__delay-0.5s" data-modal="connect-shit-wallet" onClick={handleMakeClick}>
            make $hit
        </button>

    </div>
    );
};

export default MainTasksWidget;