import React, {useEffect} from 'react';
import { BrowserRouter, Route, Routes, createBrowserRouter, RouterProvider, useNavigate, useLocation  } from 'react-router-dom';
import MainTasksWidget from './MainTasksWidget.jsx';
import ConnectWalletWidget from './ConnectWalletWidget.jsx';
import ClaimerWidget from './ClaimerWidget.jsx';
import HeroComponent from './HeroComponent.jsx';
import BalanceComponent from './BalanceComponent.jsx';
import { usePopup } from '../../Popups/PopupContext.js';

const HomeScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { heroState, setHeroState } = usePopup();
    let isBalanceVisible = location.pathname === '/home/tasks' || location.pathname === '/home/claimer';

    useEffect(() => {
    
        console.log(heroState)
        if (heroState == 'claimer' || heroState == 'claim_now')
        {
            navigate('/home/claimer');
        }
    }, [heroState]);

    useEffect(() => {
        /*
        const timer = setTimeout(() => {
            navigate('/home');
        }, 3000); // 3 seconds delay
        return () => clearTimeout(timer);*/
        console.log(heroState)
        //navigate('/home/tasks');
    }, [navigate]);

    const handleMakeClick = () => {
        setHeroState('claimer')
    }

    // Example of how to update heroState
    const handleChangeHeroState = () => {
        setHeroState(prevState => ({
            ...prevState,
           
        }));
    };

    return (
        <div className="main main__body">
           {/* <div className="main__container container">
                <div className="main__body">*/}

                    <HeroComponent />
                    <BalanceComponent visible={isBalanceVisible} title={"your $hit"}  />

                    <Routes>
                        <Route path="*" element={<ConnectWalletWidget />} />
                        <Route path="tasks" element={<MainTasksWidget />} />
                        <Route path="claimer" element={<ClaimerWidget />} />
                    </Routes>

{/*
                    <div>
                        <p>Current Hero State: {JSON.stringify(heroState)}</p>
                        <button onClick={handleMakeClick}>Change Hero State</button>
                    </div>*/}
            
        </div>
    );
};

export default HomeScreen;