import React, {useEffect} from 'react';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas';
import heroAnimation from "../../../assets/animations/potato.riv";
import { usePopup } from '../../Popups/PopupContext.js';

const HeroComponent = () => {
    const { heroState, setHeroState } = usePopup();

    const { rive, RiveComponent } = useRive({
        src: heroAnimation,
        stateMachines: "State Machine 1",
        animations: 'idle',
        autoplay: true,
        onInit: (riveInstance) => {
            console.log('init')
            goToMeditation1(riveInstance);
        }
        /*
        onStop: (event) => {
            //console.log('Animation stopped', event);
            //console.log(event.data[0])
            if (event.data[0] === 'go_to_meditation_1') {
                playMeditation1Animation();
            }
        }*/
    });
    useEffect(() => {
    
        console.log(heroState)
        if (heroState == 'claim_now')
        {
            goToMeditation2(rive);
        }
        else if (heroState == 'default')
        {
            //goToDefault(rive)
            goToMeditation1(rive);
        }
        //else {
          //  goToMeditation1(rive);
        //}
    }, [heroState]);


    if (rive)
        console.log(rive)

    const goToMeditation1 = (rive) =>
    {
        rive.pause();
        rive.play("go_to_meditation_1")
        setTimeout(function() {
            rive.play("meditation_1")
        },2000)
    }


    const goToMeditation2 = (rive) =>
        {
            rive.pause();
            rive.play("go_to_meditation_2")
            setTimeout(function() {
                rive.play("meditation_2")
            },2000)
        }

    const goToDefault = (rive) =>
            {
                rive.pause();
                rive.play("idle")
            }

    const animations = () => {
        rive.pause();
        //rive.play("tap")
        //rive.play("tap_boost")
        //rive.play("idle_boost")
        //rive.play("go_to_meditation_1")
        //rive.play("meditation_1")
        //rive.play("meditation2")
    }

    /*
    const meditation1AnimationTrigger = useStateMachineInput(
        rive,
        'State Machine 1',
        'meditation1' // The exact name from your Rive file
    );

    const playMeditation1Animation = () => {
        if (meditation1AnimationTrigger) {
            meditation1AnimationTrigger.fire(); // Trigger the animation
        }
    };
    */

    const getClassName = () => {
        if (heroState === 'claim_now' || heroState === 'default' ) {
            return 'main__image_yellow';
        }
        else 
        // Default case
        return 'main__image_gray';
    };

    return (
        <div className={`main__image animate__animated animate__fadeIn  ${getClassName()}`}>
            <RiveComponent />
        </div>
    );

}

export default HeroComponent;