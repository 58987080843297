import React, { createContext, useContext, useState } from 'react';

const PopupContext = createContext();

export const usePopup = () => useContext(PopupContext);

export const PopupProvider = ({ children }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupContent, setPopupContent] = useState(null);
    const [heroState, setHeroState] = useState('cool_down');
    const [userBalance, setUserBalance] = useState(0);

    const showPopup = (content) => {
        setPopupContent(content);
        setIsPopupOpen(true);
        //const body = document.querySelector('body');
        //body.classList.add('_lock');
    };

    const hidePopup = () => {
        setIsPopupOpen(false);
        setPopupContent(null);
    };

    return (
        <PopupContext.Provider value={{ isPopupOpen, popupContent, showPopup, hidePopup, heroState, setHeroState, userBalance, setUserBalance }}>
            {children}
        </PopupContext.Provider>
    );
};
