import React, {useState} from 'react';
import { usePopup } from './PopupContext';
import TaskList from "../Screens/Home/Tasks/TaskList";
import TaskForm from "../Screens/Home/Tasks/TaskForm";
import { CSSTransition } from 'react-transition-group';
import Icons from '../Common/IconsComponent';
//import './Popup.css'; // Include your styles for the popup

const Popup = () => {
    const { isPopupOpen, popupContent, hidePopup } = usePopup();
    const [showForm, setShowForm] = useState(false);
    //if (!isPopupOpen) return null;
    const handleSP = () => {
        //setShowForm(!showForm);
        setShowForm(prevShowForm => !prevShowForm);
    };
    return (

        <div onClick={hidePopup}>
        <div  className={`modal ${isPopupOpen ? '_active' : ''}`}>
            <div className="modal__wrapper"  onClick={(e) => e.stopPropagation()}>
                <div className="modal__content">
                    <button className='modal__close_btn' onClick={hidePopup}><Icons.Close/></button>
                    
                    {/*<div className="modal__icon">
                        <svg>
                            <use href="@img/icons/icons.svg#user2"></use>
                        </svg>
                    </div>*/}

                    {popupContent}
                </div>
            </div>
        </div>
        </div>

    );
};

export default Popup;
