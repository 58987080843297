import React, {useEffect, useState} from 'react';
import Icons from "../../Common/IconsComponent";
import { usePopup } from '../../Popups/PopupContext.js';

const BalanceComponent = ({visible, title}) => {
    const { userBalance } = usePopup();
    const [balance, setBalance] = useState(userBalance);
    const [className, setClassName] = useState('main__value');

    useEffect(() => {
        setBalance(userBalance);
       // if (userBalance!=0) {
            setClassName('main__value animate__animated animate__tada')
            setTimeout(function(){
                setClassName('main__value')
            },2000)
        //}
    }, [userBalance]);

    if (!visible) return  null;

    return (
        <div>
            <div className="main__text">{title}</div>
            <div className={className}>
                <Icons.Shit/>
                {balance}
            </div>
        </div>
    )
}
export default BalanceComponent;